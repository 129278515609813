import { Amplify, Auth } from 'aws-amplify';
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { createAuthLink, AUTH_TYPE } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

const authConfig = {
    url: awsconfig.aws_appsync_graphqlEndpoint,
    region: awsconfig.aws_appsync_region,
    auth: {
        type: awsconfig.aws_appsync_authenticationType as AUTH_TYPE.AWS_IAM,
        credentials: () => Auth.currentCredentials(),
    },
};

const authLink = createAuthLink(authConfig);
const subscriptionLink = createSubscriptionHandshakeLink(authConfig);
const removePayloadTypeNameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        const omitTypename = (key: string, value: unknown) => (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation);
});

export const client = new ApolloClient({
    link: ApolloLink.from([authLink, removePayloadTypeNameLink, subscriptionLink]),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    applicationByQuote: {
                        keyArgs: ['id', 'quoteID'],
                    },
                    getQuote: {
                        read(_, { args, toReference }) {
                            return toReference({
                                __typename: 'Quote',
                                id: args?.id,
                            });
                        },
                    },
                    getApplication: {
                        read(_, { args, toReference }) {
                            return toReference({
                                __typename: 'Application',
                                id: args?.id,
                            });
                        },
                    },
                },
            },
        },
    }),
});
