interface FileIconProps {
    title?: string;
}

const FileIcon = ({ title, ...otherProps }: FileIconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2,
        }}
        viewBox="0 0 31 26"
        {...otherProps}
    >
        {title && <title>{title}</title>}
        <path
            d="M1.273 0C.57 0 0 .582 0 1.3v23.4c0 .718.57 1.3 1.273 1.3h25.454C27.43 26 28 25.418 28 24.7V5.2c0-.718-.57-1.3-1.273-1.3H13.364V1.3c0-.718-.57-1.3-1.273-1.3H1.273Z"
            style={{
                fill: '#fb0',
                fillRule: 'nonzero',
            }}
        />
        <path
            d="M1 26h28.044c1.08 0 1.956-.851 1.956-1.9V8.9c0-1.049-.876-1.9-1.956-1.9H4.261c-1.081 0-1.957.851-1.957 1.9v15.833C2.304 25.433 1.72 26 1 26Z"
            style={{
                fill: '#0a2f73',
                fillRule: 'nonzero',
            }}
        />
    </svg>
);
export default FileIcon;
