import { StyledSelect } from './styles';
import { Form } from 'react-bootstrap';

function Select({ label, name, options, field, errors = {}, ...otherProps }: any) {
    return (
        <StyledSelect {...otherProps}>
            <div className="form-group">
                <Form.Label htmlFor={name} className="d-block">
                    {label}
                </Form.Label>

                <div className="select">
                    <Form.Select className={errors.clientRiskLevel ? 'select-error' : ''} id={name} {...field}>
                        <option value="">Select option</option>
                        {options.map((option: string, index: number) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </Form.Select>
                </div>
            </div>
        </StyledSelect>
    );
}
export default Select;
