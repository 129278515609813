export default function TickIconSVG() {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20ZM15.2071 6.29289C15.5976 6.68342 15.5976 7.31658 15.2071 7.70711L9.20711 13.7071C8.81658 14.0976 8.18342 14.0976 7.79289 13.7071L5.79289 11.7071C5.40237 11.3166 5.40237 10.6834 5.79289 10.2929C6.18342 9.90237 6.81658 9.90237 7.20711 10.2929L8.5 11.5858L13.7929 6.29289C14.1834 5.90237 14.8166 5.90237 15.2071 6.29289Z"
                fill="#008616"
            />
        </svg>
    );
}
