import { createContext, ReactNode, useContext, useReducer } from 'react';

/**
 * State management solution based on this article:
 * https://ionic.io/blog/a-state-management-pattern-for-ionic-react-with-react-hooks
 */
interface State {
    questions: object[];
    currentQuestion: number;
    answers: object[];
    reportBuilderData: object;
    riskRating?: number;
    riskRatingDescription?: string;
    uploadImageID?: number;
    uploadLogoImage?: string;
    pdfUrl: string;
    steps: number;
    currentStep: number;
    uploadPdfFileID?: number;
    uploadPDF: object;
}

interface ContextProviderProps {
    children?: ReactNode;
}

export const AppContext = createContext<any>({} as State);

export const useAppContext = () => useContext(AppContext);

export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const UPDATE_ANSWERS = 'UPDATE_ANSWERS';
export const SET_RISK_RATING = 'SET_RISK_RATING';
export const SET_RISK_RATING_DESCRIPTION = 'SET_RISK_RATING_DESCRIPTION';
export const RESET_FORM = 'RESET_FORM';

export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const UPDATE_REPORT_BUILDER_FIELDS = 'UPDATE_REPORT_BUILDER_FIELDS';
export const UPDATE_STEPS = 'UPDATE_STEPS';

export const SET_UPLOAD_IMAGE_ID = 'SET_UPLOAD_IMAGE_ID';
export const SET_UPLOAD_LOGO_IMAGE = 'SET_UPLOAD_LOGO_IMAGE';
export const SET_UPLOAD_PDF_ID = 'SET_UPLOAD_PDF_ID';
export const SET_UPLOAD_PDF = 'SET_UPLOAD_PDF';

export const CREATE_PDF = 'CREATE_PDF';
export const RESET_CREATE_PDF = 'RESET_CREATE_PDF';

interface AnswerProps {
    questionId: number;
    responseId: number;
}

export const reducer = (state: any, action: any) => {
    switch (action.type) {
        case SET_QUESTIONS: {
            if (!state.questions) {
                return state;
            }
            return {
                ...state,
                questions: action.questions,
            };
        }

        case SET_CURRENT_QUESTION: {
            return {
                ...state,
                currentQuestion: action.currentQuestion,
            };
        }

        case SET_CURRENT_STEP: {
            return {
                ...state,
                currentStep: action.currentStep,
            };
        }

        case UPDATE_ANSWERS: {
            // Check if the answer for the current questionId already exists
            const index = state.answers.findIndex(
                (answer: AnswerProps) => answer.questionId === action.answer.questionId
            );

            // If the answer exists, update it; otherwise, add it
            if (index > -1) {
                state.answers[index] = action.answer;
            } else {
                state.answers.push(action.answer);
            }
            return { ...state };
        }

        case SET_RISK_RATING: {
            return {
                ...state,
                riskRating: action.riskRating,
            };
        }

        case SET_RISK_RATING_DESCRIPTION: {
            return {
                ...state,
                riskRatingDescription: action.description,
            };
        }

        case SET_UPLOAD_PDF_ID: {
            return {
                ...state,
                uploadPdfFileID: action.uploadPdfFileID,
            };
        }

        case SET_UPLOAD_IMAGE_ID: {
            return {
                ...state,
                uploadImageID: action.uploadImageID,
            };
        }

        case SET_UPLOAD_LOGO_IMAGE: {
            return {
                ...state,
                uploadLogoImage: action.uploadLogoImage,
            };
        }

        case SET_UPLOAD_PDF: {
            return {
                ...state,
                uploadPDF: action.uploadPDF,
            };
        }

        case RESET_FORM: {
            return {
                ...state,
                currentQuestion: 1,
                answers: [],
                riskRating: undefined,
            };
        }

        case UPDATE_REPORT_BUILDER_FIELDS: {
            return {
                ...state,
                reportBuilderData: action.reportBuilderData,
            };
        }

        case CREATE_PDF: {
            return {
                ...state,
                pdfUrl: action.url,
            };
        }

        case RESET_CREATE_PDF: {
            return {
                ...state,
                pdfUrl: '',
            };
        }
        default: {
            return state;
        }
    }
};

const logger = (reducer: Function) => {
    const reducerWithLogger = (state: object, action: any) => {
        console.log('%cPrevious State: color: #9E9E9E; font-weight: 700;', state);
        console.log('%cAction:', 'color: #00A7F7; font-weight: 700;', action);
        console.log('%cNext State:', 'color: #47B04B; font-weight: 700;', reducer(state, action));
        return reducer(state, action);
    };

    return reducerWithLogger;
};

const loggerReducer = logger(reducer);

export const initialState: State = {
    questions: [],
    steps: 4,
    currentQuestion: 1,
    currentStep: 1,
    answers: [],
    reportBuilderData: {},
    pdfUrl: '',
    riskRating: undefined,
    riskRatingDescription: undefined,
    uploadImageID: undefined,
    uploadLogoImage: undefined,
    uploadPdfFileID: undefined,
    uploadPDF: {},
};

export function AppContextProvider({ children }: ContextProviderProps) {
    const fullInitialState = {
        ...initialState,
    };

    let [state, dispatch] = useReducer(loggerReducer, fullInitialState);
    let value = { state, dispatch };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export const AppContextConsumer = AppContext.Consumer;
