import styled from 'styled-components';
import { breakpointUp } from '../../../helpers/breakpoints';
import iconButton from '../../../assets/img/icon-button.svg';
import iconButtonWhite from '../../../assets/img/icon-button-white.svg';

type CustomButton = {
    iconColor?: string;
};

export const StyledDropZone = styled.div<CustomButton>`
    margin-top: 16px;

    .dropzoneContainer {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .error-text {
        color: ${({ theme }) => theme.colors.red};
    }

    button {
        display: flex;
        align-items: center;
    }
    button:before {
        content: '';
        background: url(${({ theme, iconColor }) => (iconColor === 'secondary' ? iconButton : iconButtonWhite)})
            no-repeat left center;
        display: flex;
        width: 28px;
        height: 28px;
    }

    label {
        display: block;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        color: ${({ theme }) => theme.colors.primaryText};
    }
    input {
        border-radius: 8px;
        padding: 10px 16px;
        width: 368px;
        height: 44px;

        ${breakpointUp.lg`
            
        `}
    }
`;
