import styled from 'styled-components';
import { IonItem as IonicItem, IonLabel as IonicLabel, IonList as IonicList } from '@ionic/react';

export const List = styled(IonicList)``;

export const ListItem = styled(IonicItem)`
    --padding-start: 0;
    --inner-border-width: 0;
    --min-height: auto;
    --color: ${({ theme }) => theme.colors.bodyText};
    display: flex;
    align-items: flex-start;
    padding-bottom: 16px;
`;

export const ListLabel = styled(IonicLabel)`
    margin: 0 0 0 8px;
    white-space: normal !important;
`;
