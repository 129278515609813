import styled from 'styled-components';
import { breakpointUp } from '../../helpers/breakpoints';
import { Container as RBContainer } from 'react-bootstrap';

export const Container = styled(RBContainer)`
    ${breakpointUp.lg`
        width: 756px;
        padding: 0 15px;
    `}
`;
