import styled from 'styled-components';
import Skeleton from '../ui/Skeleton';

export const ProgressBarWrapper = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.bodyText};
    margin: 8px 0;
`;

export const ProgressBar = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 -4px;
`;

export const ProgressBarStep = styled.div`
    flex: 1;
    height: 8px;
    border-radius: 4px;
    margin: 0 4px;
`;

export const ProgressLabel = styled.p`
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.bodyText};
    margin-top: 8px;
    margin-bottom: 25px;
`;

export const ProgressBarSkeleton = styled(Skeleton)`
    height: 8px;
    margin-bottom: 12px;
`;

export const LabelSkeleton = styled(Skeleton)`
    max-width: 120px;
    height: 14px;
    margin-bottom: 30px;
`;
