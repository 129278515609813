import { StepContainer, StepLabel, StepStyle, StepWrapper, StepsLabelContainer } from './styles';
import TickIconSVG from './iconTick';

interface ReportBuilderProgressStepperProps {
    steps: {
        step: number;
        label: string;
    }[];
    currentStep: number;
}

export default function ReportBuilderProgressStepper({ steps, currentStep }: ReportBuilderProgressStepperProps) {
    const totalSteps = steps.length;
    const width = `${(100 / (totalSteps - 1)) * (currentStep - 1)}%`;

    return (
        <StepContainer width={width}>
            {steps.map(({ step, label }) => (
                <StepWrapper key={step}>
                    <StepStyle
                        className="step"
                        currentStep={currentStep === step}
                        step={currentStep > step ? 'completed' : 'incomplete'}
                    >
                        {(currentStep > step || currentStep === 3) && (
                            <div>
                                <TickIconSVG />
                            </div>
                        )}
                    </StepStyle>
                    <StepsLabelContainer>
                        <StepLabel key={step}>{label}</StepLabel>
                    </StepsLabelContainer>
                </StepWrapper>
            ))}
        </StepContainer>
    );
}
