import styled from 'styled-components';
import { H5 } from '../Typography';
import uiInfoIcon from './InfoIcon';

export const AlertWrapper = styled.div`
    position: relative;
    padding: 16px 16px 16px 48px;
    color: ${({ theme }) => theme.colors.primaryText};
    background-color: ${({ theme }) => theme.colors.paleBlue};
    border: 1px solid ${({ theme }) => theme.colors.primaryAction};
    border-left-width: 4px;
    border-radius: 8px;
`;

export const AlertTitle = styled(H5)`
    margin-top: 0;
`;

export const InfoIcon = styled(uiInfoIcon)`
    position: absolute;
    top: 20px;
    left: 16px;
    width: 20px;
`;
