import { H5 } from '../Typography';
import { StyledRadioGroup } from './styles';
import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';

function RadioGroup({ children, title, ...otherProps }: any) {
    return (
        <StyledRadioGroup {...otherProps}>
            <HeadlessRadioGroup.Label>
                <H5 className="mt-0 mb-4">{title}</H5>
            </HeadlessRadioGroup.Label>
            {children}
        </StyledRadioGroup>
    );
}
export default RadioGroup;
