import { breakpointUp } from 'helpers/breakpoints';
import styled from 'styled-components';

export const StyledReportBuilderSteps = styled.div``;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    margin: 50px 0;
    ${breakpointUp.md`
       flex-direction: row;
    `}

    button:last-child {
        margin-bottom: 20px;

        ${breakpointUp.md`
            margin-bottom: 0;
        `}
    }
`;
