import styled from 'styled-components';
import { breakpointUp } from '../../helpers/breakpoints';

import { Row as RBRow, Container as RBContainer } from 'react-bootstrap';

export const Container = styled(RBContainer)`
    ${breakpointUp.lg`
        width: 1192px;
    `}
`;

export const Row = styled(RBRow)`
    .col-mobile-bottom-margin {
        margin-bottom: 32px;

        ${breakpointUp.lg`
            margin-bottom: 0;
        `}
    }

    .report-builder__image {
        width: 100%;
        display: block;
        margin: 0 auto 40px;

        ${breakpointUp.lg`
            width: 50%;
            margin: 0 auto;
        `}
    }
`;
