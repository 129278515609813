interface MeterIconProps {
    title?: string;
}

const RiskLevelIcon = ({ title, ...otherProps }: MeterIconProps) => (
    <svg
        viewBox="0 0 48 40"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2,
        }}
        {...otherProps}
    >
        {title && <title>{title}</title>}
        <path
            d="M38.475 4.855a.967.967 0 0 0-1.412.289l-5.197 8.663c-.26.432-.154.986.218 1.325a11.967 11.967 0 0 1 3.875 7.869c.045.551.489.999 1.041.999h10a.97.97 0 0 0 .98-1c-.304-7.409-3.966-13.951-9.505-18.145Z"
            style={{
                fill: '#fb0',
                fillRule: 'nonzero',
            }}
        />
        <path
            d="M18.706 12.26c.26.432.799.599 1.274.43a11.98 11.98 0 0 1 4.021-.69c1.41 0 2.764.243 4.021.69.475.169 1.015.002 1.274-.43l5.198-8.663a.966.966 0 0 0-.408-1.381A23.908 23.908 0 0 0 24.001 0c-3.602 0-7.019.794-10.085 2.216a.966.966 0 0 0-.408 1.38l5.198 8.664Z"
            style={{
                fill: '#00c5b4',
                fillRule: 'nonzero',
            }}
        />
        <path
            d="M16.135 13.807c.259.432.153.986-.219 1.325a11.967 11.967 0 0 0-3.875 7.869c-.045.551-.489.999-1.041.999H1a.97.97 0 0 1-.98-1C.324 15.591 3.987 9.049 9.525 4.855a.967.967 0 0 1 1.412.289l5.198 8.663ZM24 15c-.579 0-1.075.414-1.179.984l-1.544 8.491a8 8 0 1 0 5.446 0l-1.544-8.491c-.104-.57-.6-.984-1.179-.984Z"
            style={{
                fill: '#0a2f73',
                fillRule: 'nonzero',
            }}
        />
    </svg>
);

export default RiskLevelIcon;
