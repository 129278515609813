import { useAppContext } from 'state';

export const useReportBuilderData = () => {
    const { state } = useAppContext();

    const { reportBuilderData, riskRating } = state;
    const { clientsName, clientRiskLevel, dateprepared, preparedby, pdfFile, logoImage } = reportBuilderData;

    let riskString;

    const convertRiskRatingToString = (riskRating: number) => {
        switch (riskRating) {
            case 1:
                return 'Lower';
            case 2:
                return 'Lower-Medium';
            case 3:
                return 'Medium';
            case 4:
                return 'Medium-Higher';
            case 5:
                return 'Higher';
            default:
                return ''; // Return an empty string or handle the default case accordingly
        }
    };

    if (riskRating) {
        riskString = convertRiskRatingToString(riskRating);
    }

    return {
        riskString,
        clientsName,
        clientRiskLevel,
        dateprepared,
        preparedby,
        pdfFile,
        logoImage,
    };
};
