import { H2, P } from '../ui/Typography';
import { BackButton, Wrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import { RESET_FORM, SET_QUESTIONS, useAppContext } from '../../state';

function Error() {
    const navigate = useNavigate();
    const { dispatch } = useAppContext();
    const handlClick = () => {
        dispatch({ type: RESET_FORM });
        dispatch({ type: SET_QUESTIONS, questions: [] });
        navigate('/');
    };

    return (
        <Wrapper>
            <H2>Your attitude to risk...</H2>

            <P>
                Apologies we're experiencing some technical issues. We're working hard to get this up and running again,
                please try again later.
            </P>

            <BackButton secondary onClick={handlClick}>
                Back
            </BackButton>
        </Wrapper>
    );
}

export default Error;
