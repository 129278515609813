import styled from 'styled-components';
import { breakpointUp } from '../../../helpers/breakpoints';

export const StyledSelect = styled.div`
    label {
        display: block;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        color: ${({ theme }) => theme.colors.primaryText};
    }
    select {
        appearance: none;
        background: transparent;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC4yOTI4OTMgMC4yOTI4OTNDLTAuMDk3NjMxMSAwLjY4MzQxNyAtMC4wOTc2MzExIDEuMzE2NTggMC4yOTI4OTMgMS43MDcxMUw1LjI5Mjg5IDYuNzA3MTFDNS42ODM0MiA3LjA5NzYzIDYuMzE2NTggNy4wOTc2MyA2LjcwNzExIDYuNzA3MTFMMTEuNzA3MSAxLjcwNzExQzEyLjA5NzYgMS4zMTY1OCAxMi4wOTc2IDAuNjgzNDE4IDExLjcwNzEgMC4yOTI4OTRDMTEuMzE2NiAtMC4wOTc2MzA2IDEwLjY4MzQgLTAuMDk3NjMwNiAxMC4yOTI5IDAuMjkyODk0TDYgNC41ODU3OUwxLjcwNzExIDAuMjkyODkzQzEuMzE2NTggLTAuMDk3NjMxMSAwLjY4MzQxOCAtMC4wOTc2MzExIDAuMjkyODkzIDAuMjkyODkzWiIgZmlsbD0iIzA1MUEzRiIvPgo8L3N2Zz4K');
        background-repeat: no-repeat;
        background-position-x: 97%;
        background-position-y: 18px;
        border: 2px solid ${({ theme }) => theme.colors.borderGrey};
        border-radius: 8px;
        cursor: pointer;
        padding: 5px 16px;
        width: 300px;
        height: 44px;
        ${breakpointUp.lg`
            
        `}
    }

    .select-error {
        border-color: ${({ theme }) => theme.colors.red};
    }
`;
