import styled from 'styled-components';
import { Tooltip as RBTooltip } from 'react-bootstrap';

export const StyledStepOne = styled.div`
    margin-top: 32px;

    label {
        display: block;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        color: ${({ theme }) => theme.colors.primaryText};
    }

    .error-text {
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 5px 0;
        color: ${({ theme }) => theme.colors.red};
    }

    .riskRating {
        padding-top: 20px;
        padding-left: 20px;
        color: ${({ theme }) => theme.colors.primaryText};
    }
`;

export const ReportBuilderToolTip = styled(RBTooltip)`
    .tooltip-inner {
        background-color: #091024;
        color: #fafafa;
    }
`;

export const StyledDatePickerWrapper = styled.div`
    max-width: 350px;
    margin: 16px 0 24px;

    input[type='date']::-webkit-calendar-picker-indicator {
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 0C4.44772 0 4 0.447715 4 1V2H2C0.895431 2 0 2.89543 0 4V16C0 17.1046 0.89543 18 2 18H16C17.1046 18 18 17.1046 18 16V4C18 2.89543 17.1046 2 16 2H14V1C14 0.447715 13.5523 0 13 0C12.4477 0 12 0.447715 12 1V2H6V1C6 0.447715 5.55228 0 5 0ZM12 5V4H6V5C6 5.55228 5.55228 6 5 6C4.44772 6 4 5.55228 4 5V4H2V7H16V4H14V5C14 5.55228 13.5523 6 13 6C12.4477 6 12 5.55228 12 5ZM16 9H2L2 16H16V9Z' fill='%23147CB3'/%3E%3C/svg%3E%0A");
    }
`;

export const Answers = styled.div`
    display: flex;
    border: 1px solid ${({ theme }) => theme.colors.cardBorder};
    border-radius: 8px;
    padding: 10px 12px 0;
    width: 165px;
`;

export const Answer = styled.div`
    position: relative;
`;

export const RadioInput = styled.input`
    position: absolute;
    -webkit-appearance: none;
    appearance: none;
    margin: 2px 0 0 0;
    font: inherit;
    color: currentColor;
    width: 20px;
    height: 20px;
    border: 0.15em solid ${({ theme }) => theme.colors.bodyText};
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;

    &::before {
        content: '';
        width: 11px;
        height: 11px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em ${({ theme }) => theme.colors.primaryAction};
    }

    &:checked {
        border: 0.15em solid ${({ theme }) => theme.colors.primaryAction};
        &::before {
            transform: scale(1);
        }
    }
`;

export const Label = styled.label`
    display: block;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    padding: 0 30px;
    color: ${({ theme }) => theme.colors.primaryText};
`;
