import { Outlet } from 'react-router-dom';
import { useReportBuilderForm } from './hooks';
import { Container } from './styles';
import ReportBuilderProgressStepper from 'components/ReportBuilderProgressStepper/ReportBuilderProgressStepper';

function ReportBuilderForm() {
    const { onSubmit, handleSubmit, steps, currentStep } = useReportBuilderForm();

    return (
        <Container>
            <ReportBuilderProgressStepper steps={steps} currentStep={currentStep} />

            <form onSubmit={handleSubmit(onSubmit)}>
                <Outlet />
            </form>
        </Container>
    );
}
export default ReportBuilderForm;
