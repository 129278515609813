import StepOne from '../StepOne';
import StepTwo from '../StepTwo';
import { useReportBuilderForm } from 'components/ReportBuilderForm/hooks';
import Button from 'components/ui/Button';
import { ButtonContainer } from './styles';
import StepThree from '../StepThree';
import { logAnalyticsEvent } from 'services/analytics';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import debug from 'utils/debug';

const Form = () => {
    const {
        control,
        Controller,
        currentStep,
        handleBack,
        handleNext,
        setValue,
        watch,
        updateFormSubmition,
        creatingPDF,
        handleLogoImageChange,
        setScale,
        scale,
        register,
        errors,
        clearErrors,
        file,
        riskString,
        setClientPDF,
    } = useReportBuilderForm();

    const location = useLocation();
    // Access the state passed through navigation
    const { clientPDF } = location.state;

    useEffect(() => {
        debug('clientPDF', clientPDF);
        setClientPDF(clientPDF);
    }, [clientPDF, setClientPDF]);

    return (
        <>
            {/* // !* Adviser Details Step */}
            {currentStep === 1 && (
                <StepOne
                    riskString={riskString}
                    clientPDF={clientPDF}
                    register={register}
                    control={control}
                    Controller={Controller}
                    setValue={setValue}
                    watch={watch}
                    file={file}
                    errors={errors}
                    clearErrors={clearErrors}
                />
            )}

            {/* // !* Logo Step */}
            {currentStep === 2 && (
                <StepTwo
                    handleLogoImageChange={handleLogoImageChange}
                    control={control}
                    Controller={Controller}
                    setScale={setScale}
                    scale={scale}
                    watch={watch}
                />
            )}

            {/* // !* Summary Step */}
            {currentStep === 3 && <StepThree clientPDF={clientPDF} />}

            <ButtonContainer className="justify-content-between mt-3">
                {currentStep > 1 && (
                    <Button
                        secondary
                        type="button"
                        onClick={() => {
                            handleBack();
                            if (currentStep === 2) {
                                logAnalyticsEvent({ action: `Report builder | Page 2`, label: `Back button` });
                            } else if (currentStep === 3) {
                                logAnalyticsEvent({ action: `Report builder | Page 3`, label: `Back button` });
                            }
                        }}
                    >
                        Back
                    </Button>
                )}
                {currentStep < 3 && (
                    <Button
                        type="button"
                        onClick={() => {
                            handleNext();
                            if (currentStep === 1) {
                                logAnalyticsEvent({ action: `Report builder | Page 1`, label: `Next button` });
                            } else if (currentStep === 2) {
                                logAnalyticsEvent({ action: `Report builder | Page 2`, label: `Next button` });
                            }
                        }}
                    >
                        Next
                    </Button>
                )}
                {currentStep === 3 && (
                    <Button
                        onClick={() => {
                            updateFormSubmition();
                            logAnalyticsEvent({ action: `Report builder | Page 3`, label: `Download my Report` });
                        }}
                        type="submit"
                    >
                        {!creatingPDF ? 'Download my Report' : 'Downloading report...'}
                    </Button>
                )}
            </ButtonContainer>
        </>
    );
};

export default Form;
