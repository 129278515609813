import styled from 'styled-components';

import { IonCard, IonCardContent, IonCardTitle } from '@ionic/react';
import { breakpointUp } from 'helpers/breakpoints';

export const StyledCard = styled(IonCard)`
    height: 100%;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.cardBorder};
    padding: 16px;
    box-shadow: none;
    border-radius: 8px;
    margin: 0;
`;

export const CardTitle = styled(IonCardTitle)`
    font-size: 22px;
    font-weight: 500;
    margin: 0;
`;

export const CardContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    .card-button {
        margin-top: auto;
        align-self: flex-start;
    }
`;

export const CardFlex = styled.div`
    ${breakpointUp.md`
        display: flex;
        align-items: flex-start;
        gap: 16px;
        height: 100%;
    `}
`;

export const CardContent = styled(IonCardContent)`
    margin: 0;
    padding: 0 0 16px;

    p {
        font-size: 16px;
        max-width: 90%;
    }
`;
