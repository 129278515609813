const riskRatings: any = {
    '1': {
        rating: 'Lower',
        description: `You're likely to be more conservative with your investments and understand that there may be some short-term changes in value to get potentially modest or relatively stable returns.`,
    },
    '2': {
        rating: 'Lower to Medium',
        description: `You're relatively cautious with your investments. You want the potential of getting reasonable long-term returns and understand there may be some frequent but small changes in the value of your investments.`,
    },
    '3': {
        rating: 'Medium',
        description: `You have a balanced approach to risk. You don't look for risky investments, but you don't avoid them either. You're prepared to accept fluctuations in the value of your investments to try and get potentially better long-term returns. You understand that the value of your investments might change frequently and sometimes significantly.`,
    },
    '4': {
        rating: 'Medium to Higher',
        description: `You're comfortable with investment risk. You want to get potentially higher long-term returns and understand that the value of your investments is likely to change frequently and often significantly`,
    },
    '5': {
        rating: 'Higher',
        description: `You're very comfortable taking investment risk. You're aiming for potentially high long-term returns and are less concerned if the value of your investments go up and down over the short or medium term. You understand that the value of your investments is likely to change very frequently and significantly.`,
    },
};

export default riskRatings;
