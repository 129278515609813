import { useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { graphQLQuery } from '../../services/graphql/query';
import { SET_CURRENT_QUESTION, SET_QUESTIONS, UPDATE_ANSWERS, useAppContext } from '../../state';
import { logAnalyticsEvent } from 'services/analytics';

export interface AnswerProps {
    questionId: number;
    responseId: number;
}
export const useQuestionForm = () => {
    const { state, dispatch } = useAppContext();
    const navigate = useNavigate();

    const isLastQuestion = state?.currentQuestion === state?.questions.length;
    const selectedAnswers = useMemo(() => state?.answers ?? [], [state?.answers]);

    useEffect(() => {
        if (state?.questions.length && state?.currentQuestion) {
            logAnalyticsEvent({ action: `Question ${state.currentQuestion}`, label: `Display` });
        }
    }, [state.questions, state.currentQuestion]);

    useEffect(() => {
        async function fetchQuestionsData() {
            try {
                const questions = await graphQLQuery('getQuestions');
                dispatch({ type: SET_QUESTIONS, questions });
            } catch {
                navigate('/error');
            }
        }
        fetchQuestionsData();
    }, [dispatch, navigate]);

    useEffect(() => {
        if (state.answers.length > 0 && state.answers.length === state.questions.length) {
            navigate('/results');
        }
    }, [state, navigate]);

    const question = state?.questions ? state.questions[state.currentQuestion - 1] : null;

    // let foundAnswer: AnswerProps | undefined
    const handleBack = () => {
        dispatch({
            type: SET_CURRENT_QUESTION,
            currentQuestion: Math.max(state.currentQuestion - 1, 1), // Ensure the currentQuestion doesn't go below 1
        });

        const previousAnswer = selectedAnswers.find(
            (answer: AnswerProps) => answer.questionId === state.currentQuestion - 1
        );

        if (previousAnswer) {
            setValue('answer', previousAnswer.responseId.toString());
        }
    };

    const { handleSubmit, register, formState, reset, control, setValue, watch } = useForm();
    const selectedAnswer = watch('answer');

    const onSubmit = useCallback(
        async ({ answer }: any) => {
            dispatch({
                type: UPDATE_ANSWERS,
                answer: { questionId: parseInt(state.currentQuestion, 10), responseId: parseInt(answer, 10) },
            });
            dispatch({
                type: SET_CURRENT_QUESTION,
                currentQuestion: Math.min(state.currentQuestion + 1, state.questions.length),
            });

            const nextAnswer = selectedAnswers.find(
                (answer: AnswerProps) => answer.questionId === state.currentQuestion + 1
            );

            if (nextAnswer) {
                setValue('answer', nextAnswer.responseId.toString());
            } else {
                reset({ answer: '' }); // Clear the answer field only, so the next question starts fresh
            }
        },
        [state, dispatch, reset, selectedAnswers, setValue]
    );

    const buttonLabel = isLastQuestion ? 'Submit' : 'Next';

    return {
        handleSubmit,
        register,
        formState,
        question,
        buttonLabel,
        onSubmit,
        handleBack,
        control,
        selectedAnswer,
    };
};
