import { LabelSkeleton, ProgressBarSkeleton } from './styles';

function LoadingState() {
    return (
        <div>
            <ProgressBarSkeleton />
            <LabelSkeleton />
        </div>
    );
}

export default LoadingState;
