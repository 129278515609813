import styled from 'styled-components';

export const StyledStepThree = styled.div`
    margin: 16px 0 50px;
    .flex {
        display: flex;
    }
    .navy {
        color: ${({ theme }) => theme.colors.dark};
    }

    .blue {
        color: ${({ theme }) => theme.colors.primaryActionHover};
    }

    .text-padding {
        padding-left: 16px;
    }

    .logo {
        width: 150px;
    }
`;
