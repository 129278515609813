import { TitleSkeleton, ResultsSkeleton } from './styles';

function LoadingState() {
    return (
        <div>
            <TitleSkeleton />
            <ResultsSkeleton />
        </div>
    );
}

export default LoadingState;
