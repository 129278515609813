import styled, { keyframes } from 'styled-components';

const pulseFade = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
`;

export const SkeletonWrapper = styled.div`
    width: 100%;
    height: 20px;
    background-color: ${({ theme }) => theme.colors.loadingSkeleton};
    border-radius: 5px;
    animation-name: ${pulseFade};
    animation-duration: 1.75s;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
    animation-iteration-count: infinite;
`;
