import { Form } from 'react-bootstrap';
import { StyledInput } from './styles';

function Input({ label, className, name, type, placeholder, field, ...otherProps }: any) {
    return (
        <StyledInput {...otherProps}>
            <Form.Label htmlFor={name}>{label}</Form.Label>
            <input
                type={type}
                id={name}
                className={`form-control ${className ? className : ''}`}
                placeholder={placeholder}
                {...field}
            />
        </StyledInput>
    );
}
export default Input;
