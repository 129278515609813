import { AlertTitle, AlertWrapper, InfoIcon } from './styles';

function Alert({ children, title, ...otherProps }: any) {
    return (
        <AlertWrapper role="alert" {...otherProps}>
            <InfoIcon />

            <AlertTitle>{title}</AlertTitle>

            {children}
        </AlertWrapper>
    );
}
export default Alert;
