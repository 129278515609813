import { css } from 'styled-components';
import { stripUnit } from 'polished';

const breakpoints: any = ['320px', '480px', '768px', '1024px', '1400px'];
// breakpoint aliases
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

const mediaBreakpointUp = (size: number) => (styles: any) =>
    css`
        @media (min-width: ${size}px) {
            ${styles}
        }
    `;

const mediaBreakpointDown = (size: number) => (styles: any) =>
    css`
        @media (max-width: ${size - 1}px) {
            ${styles}
        }
    `;

const mediaBreakpointBetween = (min: number, max: number) => (styles: any) =>
    css`
        @media (min-width: ${min}px) and (max-width: ${max - 1}px) {
            ${styles}
        }
    `;

// Bootstrap style helper method for adding mobile-first (up) responsive breakpoint in styled-components
export const breakpointUp = Object.keys(breakpoints).reduce((accumulator: any, label: string) => {
    (accumulator as any)[label] = (...args: any[]) => {
        const size = parseInt(stripUnit((breakpoints as any)[label]).toString(), 10);
        return mediaBreakpointUp(size)(css({ ...args }[0]));
    };
    return accumulator;
}, {});

// Bootstrap style helper method for adding desktop-first (down) responsive breakpoint in styled-components
export const breakpointDown = Object.keys(breakpoints).reduce((accumulator, label) => {
    (accumulator as any)[label] = (...args: any[]) => {
        let size;
        switch (label) {
            case 'xxs':
                size = stripUnit(breakpoints['xs']);
                break;
            case 'xs':
                size = stripUnit(breakpoints['sm']);
                break;
            case 'sm':
                size = stripUnit(breakpoints['md']);
                break;
            case 'md':
                size = stripUnit(breakpoints['lg']);
                break;
            case 'lg':
                size = stripUnit(breakpoints['xl']);
                break;
            default:
                size = 0;
        }
        return mediaBreakpointDown(size as number)(css({ ...args }[0]));
    };
    return accumulator;
}, {});

// Bootstrap style helper method for adding styles that only apply at a single responsive breakpoint in styled-components
export const breakpointOnly = Object.keys(breakpoints).reduce((accumulator, label) => {
    (accumulator as any)[label] = (...args: any[]) => {
        if (label === 'xxs') {
            return mediaBreakpointDown(parseInt(stripUnit(breakpoints['xs']).toString(), 10))(css({ ...args }[0]));
        } else if (label === 'xl') {
            return mediaBreakpointUp(parseInt(stripUnit(breakpoints['xl']).toString(), 10))(css({ ...args }[0]));
        } else {
            const min = stripUnit(breakpoints[label]);
            let max;
            switch (label) {
                case 'xxs':
                    max = stripUnit(breakpoints['xs']);
                    break;
                case 'xs':
                    max = stripUnit(breakpoints['sm']);
                    break;
                case 'sm':
                    max = stripUnit(breakpoints['md']);
                    break;
                case 'md':
                    max = stripUnit(breakpoints['lg']);
                    break;
                case 'lg':
                    max = stripUnit(breakpoints['xl']);
                    break;
                default:
                    max = 0;
            }
            const argsList: any = { ...args };
            return mediaBreakpointBetween(parseInt(min.toString(), 10), parseInt(max.toString(), 10))(css(argsList));
        }
    };
    return accumulator;
}, {});

// Bootstrap style helper method for adding styles that apply between two responsive breakpoints in styled-components
export const breakpointBetween =
    (min: string | number, max: string | number) =>
    (...args: any[]) =>
        mediaBreakpointBetween(
            parseInt(stripUnit(breakpoints[min]).toString(), 10),
            parseInt(stripUnit(breakpoints[max]).toString(), 10)
        )(css({ ...args }[0]));
