import { StyledDropZone } from './styles';
import Button from '../Button';
import { Form } from 'react-bootstrap';
import { logAnalyticsEvent } from 'services/analytics';

function DropzoneInput({
    label,
    getInputProps,
    color,
    getRootProps,
    acceptedFiles,
    fileRejections,
    file,
    buttonText,
    eventLabel,
    eventAction,
    ...otherProps
}: any) {
    return (
        <StyledDropZone {...otherProps}>
            <Form.Label>{label}</Form.Label>
            <div className="mt-3 dropzoneContainer">
                <div {...getRootProps()}>
                    <input type="file" {...getInputProps()} />
                    <Button
                        onClick={() => logAnalyticsEvent({ action: eventAction, label: eventLabel })}
                        type="button"
                        secondary={color === 'secondary'}
                        primary={color === 'primary'}
                    >
                        {' '}
                        {buttonText}
                    </Button>
                </div>
                {fileRejections.length > 0
                    ? fileRejections &&
                      fileRejections.map((file: any) =>
                          file.errors.map((error: any, index: number) =>
                              error.code === 'file-too-large' ? (
                                  <span key={index} className="error-text">
                                      File is larger than 400kb
                                  </span>
                              ) : (
                                  <span key={index}>File is not an image</span>
                              )
                          )
                      )
                    : !file && (
                          <span>
                              <i>No file chosen</i>
                          </span>
                      )}

                {file && <span>{file.name}</span>}
            </div>
        </StyledDropZone>
    );
}
export default DropzoneInput;
