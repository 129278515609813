import styled from 'styled-components';
import { breakpointUp } from 'helpers/breakpoints';
// import { IonButton as IonicButton } from '@ionic/react';

type CustomButton = {
    secondary?: boolean;
};

export const StyledButton = styled.button<CustomButton>`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    height: 44px;
    margin: 0;
    background-color: ${({ theme, secondary }) => (!!secondary ? 'transparent' : theme.colors.primaryAction)};
    border: ${({ theme, secondary }) =>
        !!secondary ? `1px solid ${theme.colors.primaryAction}` : `2px solid ${theme.colors.primaryAction}`};
    color: ${({ theme, secondary }) => (!!secondary ? theme.colors.primaryAction : theme.colors.white)};
    border-radius: 4px;
    padding: 5px 16px;
    width: 100%;

    :hover {
        background-color: ${({ theme, secondary }) =>
            !!secondary ? theme.colors.secondaryActionHover : theme.colors.primaryActionHover};
        border: ${({ theme, secondary }) =>
            !!secondary ? `1px solid ${theme.colors.primaryAction}` : `2px solid ${theme.colors.primaryActionHover}`};
    }

    :focus {
        background-color: ${({ theme, secondary }) => (!!secondary ? 'transparent' : theme.colors.primaryAction)};
        border: ${({ theme, secondary }) =>
            !!secondary
                ? `2px solid ${theme.colors.primaryActionActive}`
                : `2px solid ${theme.colors.primaryActionActive}`};
    }

    :active {
        background-color: ${({ theme, secondary }) =>
            !!secondary ? theme.colors.primaryActionActive : theme.colors.primaryActionActive};
        border: ${({ theme, secondary }) =>
            !!secondary
                ? `2px solid ${theme.colors.primaryActionActive}`
                : `2px solid ${theme.colors.primaryActionActive}`};
        color: ${({ theme, secondary }) => (!!secondary ? theme.colors.white : theme.colors.white)};
    }

    &[disabled] {
        opacity: 0.5;
    }

    ${breakpointUp.md`
        width: initial
    `}
`;

// removed the IonicButton compomnent as it fails AA accessibility tests!

// export const IonButton = styled(IonicButton)`
//     --background: #147cb3;
//     --background-hover: #9ce0be;
//     --background-activated: #88f4be;
//     --background-focused: #88f4be;
//     --color: #fff;
//     --border-radius: 4px;
//     --border-width: 0;
//     --ripple-color: transparent;
//     --padding: 5px 16px;
//     --box-shadow: none;

//     font-family: Arial, Helvetica, sans-serif;
//     font-size: 16px;
//     line-height: 24px;
//     text-transform: none;
//     height: 44px;
//     margin: 0;

//     background-color: #147cb3;

//     :hover {
//         background-color: #9ce0be;
//     }
// `;
