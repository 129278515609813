import { Answers, Answer, Fieldset, Legend, Input, Label, Navigation } from './styles';
import Button from '../ui/Button';
import { useQuestionForm } from './hooks';
import LoadingState from './LoadingState';
import { logAnalyticsEvent } from 'services/analytics';
import { Controller } from 'react-hook-form';

function QuestionForm() {
    const { handleSubmit, question, buttonLabel, onSubmit, handleBack, control, selectedAnswer } = useQuestionForm();

    if (!question) return <LoadingState />;
    const { id: questionId, text, answers } = question;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Fieldset id={`question${questionId}Answer`}>
                <Legend>{text}</Legend>

                <Answers>
                    {answers.map(({ id, text }: any) => (
                        <Answer key={id}>
                            <Controller
                                render={({ field }: any) => (
                                    <Input
                                        type="radio"
                                        id={`question${id}`}
                                        checked={field.value === id.toString()}
                                        {...field}
                                        value={id.toString()}
                                    />
                                )}
                                control={control}
                                name="answer"
                                rules={{ required: true }}
                            />

                            <Label htmlFor={`question${id}`}>{text}</Label>
                        </Answer>
                    ))}
                </Answers>
            </Fieldset>

            <Navigation className={questionId === '1' ? 'question1' : ''}>
                {questionId > 1 ? (
                    <Button secondary type="button" onClick={() => handleBack()}>
                        Back
                    </Button>
                ) : null}

                <Button
                    type="submit"
                    onClick={() =>
                        buttonLabel === 'Next'
                            ? logAnalyticsEvent({ action: `Question ${questionId}`, label: `Next question button` })
                            : logAnalyticsEvent({ action: `Question ${questionId}`, label: `Submit button` })
                    }
                    disabled={!selectedAnswer}
                >
                    {buttonLabel}
                </Button>
            </Navigation>
        </form>
    );
}
export default QuestionForm;
