import { useAppContext } from '../../state';
import Progress from '../ProgressBar';
import QuestionForm from '../QuestionForm';
import { H2 } from '../ui/Typography';
import { Container } from './styles';

function Questionnaire() {
    const { state } = useAppContext();

    return (
        <Container>
            <H2>Your attitude to risk</H2>

            <Progress currentStep={state?.currentQuestion} totalSteps={state?.questions.length} />

            <QuestionForm />
        </Container>
    );
}

export default Questionnaire;
