import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DropzoneInput from 'components/ui/DropzoneInput';
import Select from 'components/ui/Select';
import Input from 'components/ui/Input';
import { H2, H3 } from 'components/ui/Typography';
import { StyledDatePickerWrapper, StyledStepOne } from './styles';
import { ToolTipInfo } from './ToolTipInfo';
import { useSetupDropZone } from './Hooks';
import iconError from '../../../assets/img/iconError.svg';
import { logAnalyticsEvent } from 'services/analytics';

function StepOne({ riskString, clientPDF, control, Controller, setValue, errors, clearErrors, file }: any) {
    const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useSetupDropZone(setValue);

    useEffect(() => {
        logAnalyticsEvent({ action: `Report builder | Page 1`, label: `Display` });
    }, []);

    return (
        <StyledStepOne>
            <H2>Report Builder</H2>
            <H3>Add client details</H3>

            <Controller
                control={control}
                name="clientsName"
                defaultValue=""
                render={({ field }: any) => (
                    <Input field={field} type="text" label="Client's name" placeholder="Enter client's name" />
                )}
            />

            <Form.Label htmlFor="clientRiskLevel" className="mt-3 mb-0">
                Client's risk level
                {clientPDF && (
                    <ToolTipInfo tooltipText="You can find your client's risk level on the 1st page of the client's results report" />
                )}
            </Form.Label>

            {/* if ClientPDF isn't required prepopulate the risklevel with the chosen option. */}
            {!clientPDF ? (
                <p className="riskRating">{riskString}</p>
            ) : (
                <Controller
                    control={control}
                    name="clientRiskLevel"
                    rules={{ required: 'Please select the risk level' }}
                    render={({ field }: any) => (
                        <div>
                            <Select
                                errors={errors}
                                options={['Lower', 'Lower-Medium', 'Medium', 'Medium-Higher', 'Higher']}
                                field={field}
                                onChange={() => clearErrors()}
                            />
                            {errors.clientRiskLevel && (
                                <div className="error-text">
                                    {' '}
                                    <img src={iconError} alt="error" /> {errors.clientRiskLevel.message}
                                </div>
                            )}
                        </div>
                    )}
                />
            )}

            {clientPDF && (
                <Controller
                    name="pdfFile"
                    control={control}
                    render={() => (
                        <DropzoneInput
                            eventLabel="Choose a pdf file button"
                            eventAction="Report builder 1"
                            label="Upload client's Risk Questionnaire results to include in the report"
                            color="secondary"
                            iconColor="secondary"
                            buttonText="Choose a pdf file"
                            acceptedFiles={acceptedFiles}
                            fileRejections={fileRejections}
                            getInputProps={getInputProps}
                            getRootProps={getRootProps}
                            file={file}
                        />
                    )}
                />
            )}

            <div className="mt-5">
                <H3>Add adviser details</H3>
                <Controller
                    control={control}
                    name="preparedby"
                    defaultValue=""
                    render={({ field }: any) => (
                        <Input field={field} type="text" label="Prepared by" placeholder="Enter adviser's name" />
                    )}
                />

                <StyledDatePickerWrapper>
                    <Controller
                        control={control}
                        name="dateprepared"
                        defaultValue={new Date().toISOString().split('T')[0]}
                        render={({ field }: any) => (
                            <Input field={field} type="date" label="Date prepared" placeholder="11 Feb 2022" />
                        )}
                    />
                </StyledDatePickerWrapper>
            </div>
        </StyledStepOne>
    );
}

export default StepOne;
