import { AnswersSkeleton, ButtonSkeleton, Navigation, QuestionSkeleton } from './styles';

function LoadingState() {
    return (
        <div>
            <QuestionSkeleton />
            <AnswersSkeleton />
            <Navigation>
                <ButtonSkeleton />
            </Navigation>
        </div>
    );
}

export default LoadingState;
