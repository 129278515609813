interface InfoIconProps {
    title?: string;
}

const InfoIcon = ({ title, ...otherProps }: InfoIconProps) => (
    <svg
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            strokeLinejoin: 'round',
            strokeMiterlimit: 2,
        }}
        {...otherProps}
    >
        {title && <title>{title}</title>}
        <path
            d="M12 26a1 1 0 0 1 1 1v5a1 1 0 0 1-2 0v-5a1 1 0 0 1 1-1ZM12 25a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
            style={{
                fill: '#147cb3',
                fillRule: 'nonzero',
            }}
            transform="translate(-2 -18)"
        />
        <path
            d="M12 38c5.523 0 10-4.477 10-10s-4.477-10-10-10S2 22.477 2 28s4.477 10 10 10Zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
            style={{
                fill: '#147cb3',
            }}
            transform="translate(-2 -18)"
        />
    </svg>
);

export default InfoIcon;
