import styled from 'styled-components';

type CustomButton = {
    secondary?: boolean;
};

export const StyledStepTwo = styled.div<CustomButton>`
    margin-top: 16px;

    label {
        display: block;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        color: ${({ theme }) => theme.colors.primaryText};
    }

    ul {
        padding: 0 0 0 15px;
    }
`;
