import styled from 'styled-components';
import uiRiskLevelIcon from './RiskLevelIcon';
import { H5 as uiH5 } from '../ui/Typography';
import { breakpointUp } from '../../helpers/breakpoints';
import Skeleton from '../ui/Skeleton';
import { Row as RBRow, Container as RBContainer } from 'react-bootstrap';

export const Container = styled(RBContainer)`
    ${breakpointUp.lg`
        width: 1192px;
        padding: 0 15px;
    `}
`;

export const Row = styled(RBRow)`
    .col-mobile-bottom-margin {
        margin-bottom: 32px;

        ${breakpointUp.lg`
            margin-bottom: 0;
        `}
    }
`;

// export const Container = styled.div`
//     width: 90%;
//     max-width: 800px;
//     margin: 0 auto;
//     padding: 0 15px;
// `;

export const ResultsWrapper = styled.div`
    border-radius: 8px;
    padding: 0 0 16px 0;
    margin-bottom: 16px;

    ${breakpointUp.md`
        padding: 0;
    `}
`;

export const RiskRatingCard = styled.div`
    padding: 16px 16px 32px 16px;
    margin-bottom: 30px;
    border: 1px solid ${({ theme }) => theme.colors.cardBorder};
    border-radius: 8px;
`;

export const H5 = styled(uiH5)`
    font-weight: normal;
`;

export const RiskLevelIcon = styled(uiRiskLevelIcon)`
    width: 50px;
    margin: 10px 0;
`;

export const Navigation = styled.div`
    ${breakpointUp.md`
        display: flex;
        justify-content: flex-end;
    `}
`;

export const RiskLevelsCard = styled.div`
    padding: 0 16px;
`;

export const TitleSkeleton = styled(Skeleton)`
    height: 50px;
    max-width: 450px;
    margin: 20px 0 24px 0;
`;

export const ResultsSkeleton = styled(Skeleton)`
    height: 330px;
`;
