// A simple debugger module which only debugs on environments that are not production
const debug = (msg: any, object = null) => {
    if (`${process.env.REACT_APP_DEBUG}` === 'true') {
        // eslint-disable-next-line no-console
        console.log(msg, object);
        return true;
    }

    return false;
};

export default debug;
