import styled from 'styled-components';
import { breakpointUp } from '../../../helpers/breakpoints';

export const StyledInput = styled.div`
    label {
        display: block;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        color: ${({ theme }) => theme.colors.primaryText};
    }
    input {
        border: 2px solid ${({ theme }) => theme.colors.borderGrey};
        border-radius: 8px;
        padding: 10px 16px;
        width: 100%;
        height: 44px;

        ${breakpointUp.sm`
            width: 368px;
        `}
    }
`;
