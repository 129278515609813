import { useDropzone } from 'react-dropzone';

export const useSetupDropZone = (setValue: Function) => {
    const onDrop = (acceptedFiles: File[]) => {
        setValue('pdfFile', acceptedFiles[0]);
    };

    const { getRootProps, getInputProps, acceptedFiles, fileRejections }: any = useDropzone({
        noDrag: true,
        accept: {
            'application/pdf': [],
        },
        onDrop,
        maxFiles: 1,
        multiple: false,
    });

    return {
        getRootProps,
        getInputProps,
        acceptedFiles,
        fileRejections,
    };
};
