import { SkeletonWrapper } from './styles';

function Skeleton({ children, ...otherProps }: any) {
    return (
        <SkeletonWrapper role="presentation" {...otherProps}>
            {children}
        </SkeletonWrapper>
    );
}
export default Skeleton;
