import { SVGProps } from 'react';

interface DeleteIconProps extends SVGProps<SVGSVGElement> {
    title?: string;
}

const DeleteIcon = ({ title, ...otherProps }: DeleteIconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} viewBox="0 0 16 20" fill="none" {...otherProps}>
        {title && <title>{title}</title>}
        <path
            fill="#147CB3"
            d="M5 8a1 1 0 0 1 2 0v7a1 1 0 1 1-2 0V8ZM10 7a1 1 0 0 0-1 1v7a1 1 0 1 0 2 0V8a1 1 0 0 0-1-1Z"
        />
        <path
            fill="#147CB3"
            fillRule="evenodd"
            d="M12 3V2a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v1H1a1 1 0 0 0 0 2v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V5a1 1 0 1 0 0-2h-3Zm-2-1H6v1h4V2Zm3 3H3v13h10V5Z"
            clipRule="evenodd"
        />
    </svg>
);
export default DeleteIcon;
