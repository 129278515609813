import { Routes, Route } from 'react-router-dom';
import Home from '../Home';
import QuestionnaireStart from '../QuestionnaireStart';
import Questionnaire from '../Questionnaire';
import Results from '../Results';
import Error from '../Error';
import ReportBuilderForm from 'components/ReportBuilderForm';
import ReportBuilderSteps from 'components/Pages/ReportBuilderSteps';
import { Container } from './styles';

function App() {
    return (
        <Container>
            <Routes>
                <Route path="/" element={<Home />} />

                <Route path="/risk-analyser" element={<QuestionnaireStart />} />

                <Route path="questionnaire" element={<Questionnaire />} />

                <Route path="/results" element={<Results />} />

                <Route path="/" element={<ReportBuilderForm />}>
                    <Route path="/report-builder" element={<ReportBuilderSteps />} />
                </Route>

                <Route path="/error" element={<Error />} />
            </Routes>
        </Container>
    );
}

export default App;
