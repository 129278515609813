import IconProfile from '../assets/img/icon-person.svg';
import IconLink from '../assets/img/icon-link.svg';
import IconTick from '../assets/img/icon-tick.svg';
import IconCross from '../assets/img/icon-cross.svg';

import IconClipboard from '../assets/img/icon-clipboard.svg';
import IconStatement from '../assets/img/icon-statement.svg';

import IconOneGreen from '../assets/img/icon-one-green.svg';
import IconTwoGreen from '../assets/img/icon-two-green.svg';
import IconThreeGreen from '../assets/img/icon-three-green.svg';

export const homeContent = {
    title: 'Help your clients find out their attitude to risk',
    description: 'The risk questionnaire at a glance:',
    list: [
        {
            icon: IconTick,
            iconAltText: 'tick icon',
            text: 'Designed by EV who are leading experts in the field of risk analysis',
        },
        {
            icon: IconTick,
            iconAltText: 'tick icon',
            text: 'Helps you meet compliance requirements by providing a downloadable PDF report',
        },
        {
            icon: IconTick,
            iconAltText: 'tick icon',
            text: 'Produces a customised client report',
        },
        {
            icon: IconCross,
            iconAltText: 'cross icon',
            text: 'The risk questionnaire is not suitable for clients who are unwilling to take any investment risk.',
        },
    ],
    headingOne: 'Choose from the following options:',
    cards: [
        {
            icon: IconProfile,
            iconAltText: 'profile icon',
            title: 'Are you sitting with your client?',
            paragraph1: `Start the Risk Profiler now and complete with your client. At the end you'll be able to save the results in pdf format.`,
            paragraph2: 'Alternatively, you can download this',
            paragraph3: 'It should take less than 5 minutes',
            paragraphLinkText: 'questionnaire as a pdf.',
            paragraphLink: '/pdf/risk-profiler-download.pdf',
            buttonText: 'Launch Risk Profiler',
            route: '/risk-analyser',
            link: '',
        },
        {
            icon: IconLink,
            iconAltText: 'link icon',
            title: 'Do you want to send it to your client ',
            paragraph1: `Use this option to send the Risk Profiler to your client.`,
            paragraph2: 'Once completed, your client will need to send their results back to you.',
            paragraph3: '',
            paragraphLinkText: '',
            paragraphLink: '',
            buttonText: 'Email the link',
            route: '',
            link: `mailto:?subject=Risk%20Questionnaire&body=Dear%20<client's name>%0D%0A%0D%0ABelow%20you'll%20find%20the%20link%20to%20the%20Risk%20Questionnaire%20from%20Standard%20Life.%20It%20should%20take%20around%205%20minutes%20to%20complete.
            %0D%0A%0D%0A%20https://customer-handover.risk.standardlife.co.uk%0AIf%20this%20link%20is%20not%20clickable,%20copy%20%26%20paste%20the%20link%20to%20your%20web%20browser%20address%20bar.
            %0D%0A%0D%0APlease%20email%20me%20back%20the%20PDF%20results%20after%20completing%20this%20questionnaire.%0D%0A%0D%0AI%20look%20forward%20to%20discussing%20the%20outcomes%20with%20you%20at%20our%20next%20meeting%20after I%20produce%20your%20report.%0D%0A%0D%0AKind%20regards`,
        },
    ],
    headingTwo: `Do you have your client's results?`,
    reportBuilderCard: {
        title: 'Report Builder',
        description: `Our new Report Builder lets you turn the Risk Profiler results into a personalised report. To make the most of the Report Builder, you should have in hand the following:`,
        list: [
            {
                icon: IconOneGreen,
                iconAltText: 'tick icon',
                text: `Your client's full name`,
            },
            {
                icon: IconTwoGreen,
                iconAltText: 'tick icon',
                text: `Your firm's logo`,
            },
            {
                icon: IconThreeGreen,
                iconAltText: 'tick icon',
                text: 'Risk Questionnaire results PDF from your client',
            },
        ],
        buttonText: 'Build the report',
    },
};

export const questionnaireStart = {
    title: 'Risk Analyser Questionnaire',

    riskRatings: {
        title: 'What are the risk ratings?',
        listTitle: 'Click each level to learn more',
        radioGroup: [
            {
                levelNumber: '1',
                level: 'Lower',
                eventLabel: 'What are the risk ratings? > Lower',
                eventAction: 'Risk Analyser Questionnaire page',
            },
            {
                levelNumber: '2',
                level: 'Lower-Medium',
                eventLabel: 'What are the risk ratings? > Lower-Medium',
                eventAction: 'Risk Analyser Questionnaire page',
            },
            {
                levelNumber: '3',
                level: 'Medium',
                eventLabel: 'What are the risk ratings? > Medium',
                eventAction: 'Risk Analyser Questionnaire page',
            },
            {
                levelNumber: '4',
                level: 'Medium-Higher',
                eventLabel: 'What are the risk ratings? > Medium-Higher',
                eventAction: 'Risk Analyser Questionnaire page',
            },
            {
                levelNumber: '5',
                level: 'Higher',
                eventLabel: 'What are the risk ratings? > Higher',
                eventAction: 'Risk Analyser Questionnaire page',
            },
        ],
        riskGroup: [
            {
                level: 'Lower',
                content: `You're likely to be more conservative with your investments and understand that there may be some short-term changes in value to get potentially modest or relatively stable returns.`,
            },
            {
                level: 'Lower-Medium',
                content: `You're relatively cautious with your investments. You want the potential of getting reasonable long-term returns and understand there may be some frequent but small changes in the value of your investments.`,
            },
            {
                level: 'Medium',
                content: `You have a balanced approach to risk. You don't look for risky investments, but you don't avoid them either. You're prepared to accept fluctuations in the value of your investments to try and get potentially better long-term returns. You understand that the value of your investments might change frequently and sometimes significantly.`,
            },
            {
                level: 'Medium-Higher',
                content: `You're comfortable with investment risk. You want to get potentially higher long-term returns and understand that the value of your investments is likely to change frequently and often significantly`,
            },
            {
                level: 'Higher',
                content: `You're very comfortable taking investment risk. You're aiming for potentially high long-term returns and are less concerned if the value of your investments go up and down over the short or medium term. You understand that the value of your investments is likely to change very frequently and significantly.`,
            },
        ],
        heading: 'How to assess your clients attitude to risk',
        content:
            'This EV questionnaire can help your client understand their attitude to risk but there are some limitations:',
        list: [
            {
                content: `The output of the EV questionnaire shouldn't be taken as advice`,
            },
            {
                content: `The questionnaire doesn't consider their age, financial goals or how much money they be comfortable losing i.e. capacity for loss`,
            },
            {
                content: `You and your client should also consider how long they plan to invest for and have an understanding of when they expect to access this`,
            },
        ],
    },
    attitudeRisk: {
        title: 'Take the questionnaire:',
        content:
            'The EV attitude to risk questionnaire contains 13 multiple choice questions which you need to go through with your client to check how much risk they may be comfortable taking on their investments.',
        alertHeading: `We don't store your clients data:`,
        alertContent: `You don't need to tell us anything about them, just how they would react to or feel in certain scenarios. We won't store any of this information in a way that can be linked back to them.`,
        buttonText: `I'm ready to start`,
    },
};

export const resultsContent = {
    title: 'Your risk level is',
    buttonText: 'Retake the test',
    heading: 'Next Steps',

    cards: [
        {
            icon: IconClipboard,
            iconAltText: 'Create a client report now',
            title: 'Create a client report now',
            content: `Client's results will be added to the report automatically.`,
            buttonText: 'Build my report',
        },
        {
            id: 'downloadPDF',
            icon: IconStatement,
            iconAltText: 'Download results as pdf to use in the report later',
            title: 'Download results as pdf to use in the report later',
            content: `Save the answers summary to your computer in pdf format.`,
            buttonText: 'Save results',
        },
    ],
};
