import LoadingState from './LoadingState';
import { ProgressBarWrapper, ProgressBar, ProgressBarStep, ProgressLabel } from './styles';

export interface ProgressProps {
    currentStep?: number;
    totalSteps?: number;
}

export function getStepColor(step: number, currentStep: number): string {
    if (step < currentStep) return '#008616';
    if (step === currentStep) return '#4396c2';
    return '#d9dbdd';
}

function Progress({ currentStep, totalSteps }: ProgressProps) {
    if (!currentStep || !totalSteps) return <LoadingState />;

    const steps = Array(totalSteps).fill(null);

    return (
        <ProgressBarWrapper>
            <ProgressBar
                role="progressbar"
                aria-labelledby="progresslabel"
                aria-valuemin={1}
                aria-valuemax={totalSteps}
                aria-valuenow={currentStep}
            >
                {steps.map((value: any, index: number) => (
                    <ProgressBarStep
                        key={index}
                        role="presentation"
                        style={{ backgroundColor: getStepColor(index + 1, currentStep) }}
                    />
                ))}
            </ProgressBar>
            <ProgressLabel id="progresslabel">{`Question ${currentStep} of ${totalSteps}`}</ProgressLabel>
        </ProgressBarWrapper>
    );
}

export default Progress;
