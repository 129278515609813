import styled from 'styled-components';
import { breakpointUp } from '../../helpers/breakpoints';

export const StepContainer = styled.div<{ width: string }>`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    position: relative;
    width: 80%;

    ${breakpointUp.md`
       width: 100%;
    `}

    :before {
        content: '';
        position: absolute;
        background: ${({ theme }) => theme.colors.cardBorder};
        height: 2px;
        width: 100%;
        top: 20%;
        transform: translateY(-50%);
        left: 0;
    }
    :after {
        content: '';
        position: absolute;
        background: ${({ theme }) => theme.colors.primaryAction};
        height: 2px;
        width: ${({ width }) => width};
        top: 20%;
        transition: 0.4s ease;
        transform: translateY(-50%);
        left: 0;
    }
`;

export const StepWrapper = styled.div`
    position: relative;
    z-index: 1;

    &:last-child {
        display: flex;
        flex-direction: column;
        .step {
            align-self: flex-end;
        }

        span {
            position: relative;
            left: 47px;
        }
    }
`;

export const StepStyle = styled.div<{ step: string; currentStep: boolean }>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    border: 3px solid
        ${({ theme, currentStep }) => (currentStep ? theme.colors.primaryAction : theme.colors.cardBorder)};
    transition: 0.4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StepsLabelContainer = styled.div`
    margin-top: 10px;
`;

export const StepLabel = styled.span`
    font-size: 16px;
`;
