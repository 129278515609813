import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';

export const graphQLQuery = async (name: string, args?: object) => {
    try {
        const response: any = await API.graphql(graphqlOperation((queries as any)[name], args));
        return response.data[name];
    } catch (error: any) {
        let message = '';

        if (!error.errors || !error.errors.length) {
            message = 'No error message provided';
        } else {
            let graphQLError;
            for (graphQLError of error.errors) {
                message = `${message} ${graphQLError.message}`;
            }
        }

        throw new Error(`Could not complete the GraphQL request - ${message.trim()}`);
    }
};

// export default graphQLQuery;
